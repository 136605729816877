.header {
font-weight: bold; font-size: 26px; margin-top: 10px;
}

.topic {
font-weight: bold; font-size: 21px; margin-top: 10px; margin-bottom: 10px;
}

.normal {
margin-left: 10px
}
.underNormal {
margin-left: 30px;
}
.underTopic {
font-weight: bold; font-size: 18px; margin-top: 10px; margin-left: 15px;
}
